*{
  margin: 0px;
  padding: 0px;
}

body{
  font-family: 'Fira Sans', sans-serif;
}
h2 , h3 , h1 {
  font-family: 'Marcellus SC', serif;
}
h5, h6{
  font-size: 15px;
}

.main-bg{
  background-color:#26986a;
}
.main-color{color: #26986a;}

.yellow-bg{
  background-color: #7bdcb5;
}
.yellow-color{color: rgb(123, 220, 181);;}

.nav-link{
  color: white !important;
    font-size: 16px;
    letter-spacing: 1px;
}
.free-trail:hover
{
  background-color: #26986a !important;
  color: white;
  border: 1px solid white;
}



.card-bgcolor
{
  border-radius: 20px;
}

.card-bgcolor:hover
{
  border: 1px solid gray;
}

.slider
{
  background-image: url('/public/images/home-bg.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 690px;
  margin-top: 56px !important;

}
.banner
{
  background-image: url('/public/images/bg1.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.banner-card
{
  background-color: #2c2c518c;
}


.course-card{
  box-shadow: 0px 2px 13px 0px gray;

}

.course-card:hover
{
  border: 1px solid black !important;

}

.dropdown-menu
{
  background-color: #26986a !important;
  box-shadow: 0px 2px 13px 0px gray;
}
.dropdown-item
{
  color: white;
}
.dropdown-item:hover{
  background-color: #7bdcb5;
}

.fee
{
  margin-top: 96px;
}
.feeCardHeading
{
  margin-top: -20px;
  margin-bottom: 30px;
}
.price
{
  font-size: 17px; 
  font-weight: bold;
  color: red;
}
.second-bg{
  background-color: #e6f0ee;
}

.about{
  
  margin-top: 96px !important;
}
.top-section
{
  background-image: url('/public/images/about-bg.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 520px;
}

.middle-section{
  background-color: #e6f0ee;
}
.card-border
{
  border-radius: 20px ;
}

.contact
{
  margin-top: 96px !important;
}
.top-contact
{
  background-image: url('/public/images/contact.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 400px;
}
.reach-us
{
  border: 1px solid orange;
  border-radius: 20px;
  background-color: #e6f0ee;
}
.quick-admission
{
  border: 1px solid #26986a;
  border-radius: 20px; 
}


.noorani{
  margin-top: 150px;
}


.whatsapp-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: green;
  color: white;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.whatsapp-button:hover {
  background-color: #25d366;
}



@media screen and (max-width: 768px) {
  .slider {
  height: 950px;
  }
  .top-section
  {
    background-position: right;
    height: 620px;
  }
  .slider .img-wrapper .IconImage
  {
    width: 200px;
  }
  .noorani{
    margin-top: 120px;
  }
}
